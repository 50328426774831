import React from 'react';
import { useTranslation } from 'react-i18next';

import Layout from '../common/layout';
import SEO from '../common/seo';
import Customer from '../common/customer';

import FirstImg from '../../images/guide-first-img.svg';
import ReasonsImg01 from '../../images/guide-reasons-01-img.svg';
import ReasonsImg02 from '../../images/guide-reasons-02-img.svg';
import ReasonsImg03 from '../../images/guide-reasons-03-img.svg';

import FlowStepImg01 from '../../images/guide-flow-step01-img.svg';
import FlowStepImg02 from '../../images/guide-flow-step02-img.svg';
import FlowStepImg03 from '../../images/guide-flow-step03-img.svg';
import FlowStepImg04 from '../../images/guide-flow-step04-img.svg';
import FlowStepImg05 from '../../images/guide-flow-step05-img.svg';
import FlowStepThumbnail01 from '../../images/guide-flow-step01-thumbnail.png';
import FlowStepThumbnail02 from '../../images/guide-flow-step02-thumbnail.png';
import FlowStepThumbnail03 from '../../images/guide-flow-step03-thumbnail.png';
import FlowStepThumbnail04 from '../../images/guide-flow-step04-thumbnail.png';
import FlowStepThumbnail05 from '../../images/guide-flow-step05-thumbnail.png';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const HowToPage = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Layout>
      <SEO title="Guide" />

      <div className="guide-hero">
        <div className="container">
          <h1 i18next-key="guide-hero-title">{t('guide-hero-title')}</h1>
          <p i18next-key="guide-hero-text">{t('guide-hero-text')}</p>
        </div>
      </div>

      <nav className="breadcrumb">
        <div className="container">
          <a href="/">Home</a>
          <b i18next-key="link-ws-guide-text">{t('link-ws-guide-text')}</b>
        </div>
      </nav>

      <div className="guide-for-first">
        <div className="container">
          <h2 i18next-key="guide-for-first-title">{t('guide-for-first-title')}</h2>
          <div className="guide-for-first__inner">
            <div>
              <p i18next-key="guide-for-first-text">{t('guide-for-first-text')}</p>
            </div>
            <img src={FirstImg} alt="" />
          </div>
        </div>
      </div>

      <div className="guide-reasons">
        <div className="container">
          <div>
            <h2 i18next-key="guide-reasons-title">{t('guide-reasons-title')}</h2>
            <ul>
              <li>
                <div>
                  <img src={ReasonsImg01} alt="" />
                </div>
                <h3 i18next-key="guide-reasons-01-title">{t('guide-reasons-01-title')}</h3>
                <p i18next-key="guide-reasons-01-text">{t('guide-reasons-01-text')}</p>
                <a href="https://{{link-help-correspondence-country-url}}" rel="noopener noreferrer" target="_blank" className="fas fa-chevron-circle-right">
                  <span i18next-key="link-help-correspondence-country-text">{t('link-help-correspondence-country-text')}</span>
                </a>
              </li>
              <li>
                <div>
                  <img src={ReasonsImg02} alt="" />
                </div>
                <h3 i18next-key="guide-reasons-02-title">{t('guide-reasons-02-title')}</h3>
                <p i18next-key="guide-reasons-02-text">{t('guide-reasons-02-text')}</p>
                <a href={`/categories/`} className="fas fa-chevron-circle-right">
                  <span i18next-key="link-ws-shoplist-text">{t('link-ws-shoplist-text')}</span>
                </a>
                <a href="https://{{link-help-purchase-request-url}}" className="fas fa-chevron-circle-right">
                  <span i18next-key="link-help-purchase-request-text">{t('link-help-purchase-request-text')}</span>
                </a>
              </li>
              <li>
                <div>
                  <img src={ReasonsImg03} alt="" />
                </div>
                <h3 i18next-key="guide-reasons-03-title">{t('guide-reasons-03-title')}</h3>
                <p i18next-key="guide-reasons-03-text">{t('guide-reasons-03-text')}</p>
                <a href="https://{{link-help-contactus-url}}" className="fas fa-chevron-circle-right">
                  <span i18next-key="link-help-contactus-text">{t('link-help-contactus-text')}</span>
                </a>
                <a href="https://{{link-ws-help-url}}" className="fas fa-chevron-circle-right">
                  <span i18next-key="link-ws-help-text">{t('link-ws-help-text')}</span>
                </a>
                <a href="https://{{link-help-faq-url}}" className="fas fa-chevron-circle-right">
                  <span i18next-key="link-help-faq-text">{t('link-help-faq-text')}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="guide-note">
        <div className="container">
          <a href="https://{{guide-note-link-url}}" className="guide-note__l-btn btn fas fa-chevron-right">
            <i className="fas fa-info-circle" />
            <span i18next-key="guide-note-link-text">{t('guide-note-link-text')}</span>
          </a>
        </div>
      </div>

      <div className="guide-flow">
        <div className="container">
          <div>
            <h2 i18next-key="guide-flow-title">{t('guide-flow-title')}</h2>
            <ul>
              <li className="fas fa-chevron-circle-down">
                <img src={FlowStepThumbnail01} alt="" />
                <div>
                  <h3>
                    <div className="guide-flow__icon">
                      <i className="fas fa-circle guide-flow__normal-color"></i>
                      <i className="fas fa-circle guide-flow__onesettlement-color"></i>
                    </div>
                    <span i18next-key="guide-flow-step01-title">{t('guide-flow-step01-title')}</span>
                  </h3>
                  <p i18next-key="guide-flow-step01-text">{t('guide-flow-step01-text')}</p>
                  <a href={`https://${t('link-ws-purchaserequest-url')}`} className="fas fa-chevron-circle-right">
                    <span i18next-key="link-ws-purchaserequest-text">{t('link-ws-purchaserequest-text')}</span>
                  </a>
                  <a href="https://{{link-help-purchase-request-url}}" className="fas fa-chevron-circle-right">
                    <span i18next-key="link-help-purchase-request-text">{t('link-help-purchase-request-text')}</span>
                  </a>
                  <a href="https://{{link-help-payment-method-url}}" className="fas fa-chevron-circle-right">
                    <span i18next-key="link-help-payment-method-text">{t('link-help-payment-method-text')}</span>
                  </a>
                  <img src={FlowStepImg01} alt="" />
                </div>
              </li>
              <li className="fas fa-chevron-circle-down">
                <img src={FlowStepThumbnail02} alt="" />
                <div>
                  <h3>
                    <div className="guide-flow__icon">
                      <i className="fas fa-circle guide-flow__normal-color"></i>
                      <i className="fas fa-circle guide-flow__onesettlement-color"></i>
                    </div>
                    <span i18next-key="guide-flow-step02-title">{t('guide-flow-step02-title')}</span>
                  </h3>
                  <p i18next-key="guide-flow-step02-text">{t('guide-flow-step02-text')}</p>
                  <img src={FlowStepImg02} alt="" />
                </div>
              </li>
              <li className="fas fa-chevron-circle-down">
                <img src={FlowStepThumbnail03} alt="" />
                <div>
                  <h3>
                    <div className="guide-flow__icon">
                      <i className="fas fa-circle guide-flow__normal-color"></i>
                    </div>
                    <span i18next-key="guide-flow-step03-title">{t('guide-flow-step03-title')}</span>
                  </h3>
                  <p i18next-key="guide-flow-step03-text">{t('guide-flow-step03-text')}</p>
                  <a href="https://{{link-help-shipping-method-url}}" className="fas fa-chevron-circle-right">
                    <span i18next-key="link-help-shipping-method-text">{t('link-help-shipping-method-text')}</span>
                  </a>
                  <img src={FlowStepImg03} alt="" />
                </div>
              </li>
              <li className="fas fa-chevron-circle-down">
                <img src={FlowStepThumbnail04} alt="" />
                <div>
                  <h3>
                    <div className="guide-flow__icon">
                      <i className="fas fa-circle guide-flow__normal-color"></i>
                    </div>
                    <span i18next-key="guide-flow-step04-title">{t('guide-flow-step04-title')}</span>
                  </h3>
                  <p i18next-key="guide-flow-step04-text">{t('guide-flow-step04-text')}</p>
                  <a href="https://{{link-help-payment-method-url}}" className="fas fa-chevron-circle-right">
                    <span i18next-key="link-help-payment-method-text">{t('link-help-payment-method-text')}</span>
                  </a>
                  <img src={FlowStepImg04} alt="" />
                </div>
              </li>
              <li>
                <img src={FlowStepThumbnail05} alt="" />
                <div>
                  <h3>
                    <div className="guide-flow__icon">
                      <i className="fas fa-circle guide-flow__normal-color"></i>
                      <i className="fas fa-circle guide-flow__onesettlement-color"></i>
                    </div>
                    <span i18next-key="guide-flow-step05-title">{t('guide-flow-step05-title')}</span>
                  </h3>
                  <p i18next-key="guide-flow-step05-text">{t('guide-flow-step05-text')}</p>
                  <a href="https://www.post.japanpost.jp/int/deli_days/ems/{{guide-flow-step05-ems-days-url}}" target="_blank" rel="noopener noreferrer" className="fas fa-chevron-circle-right">
                    <span i18next-key="guide-flow-step05-ems-days-text">{t('guide-flow-step05-ems-days-text')}</span>
                  </a>
                  <img src={FlowStepImg05} alt="" />
                </div>
              </li>
            </ul>

            <div className="guide-onesettlement">
              <h3 i18next-key="guide-flow-onesettlement-title">{t('guide-flow-onesettlement-title')}</h3>
              <div>
                <div>
                  <strong className="guide-onesettlement__normal-color">
                    <i className="fas fa-circle"></i>
                    <span i18next-key="guide-onesettlement-twice-title">{t('guide-onesettlement-twice-title')}</span>
                  </strong>
                  <p i18next-key="guide-onesettlement-twice-text">{t('guide-onesettlement-twice-text')}</p>
                </div>
                <div>
                  <strong className="guide-onesettlement__onesettlement-color">
                    <i className="fas fa-circle"></i>
                    <span i18next-key="guide-onesettlement-once-title">{t('guide-onesettlement-once-title')}</span>
                  </strong>
                  <p i18next-key="guide-onesettlement-once-text">{t('guide-onesettlement-once-text')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Customer />
    </Layout>
  );
};

export default HowToPage;
